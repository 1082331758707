import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { StyledToaster } from './ToastContainer.styles';
const ToastContainer = (props: PropsWithClassName): React.ReactElement => {
  const theme = useTheme();
  return <div data-testid={props.dataTestId ?? 'toast-container-root'} data-sentry-component="ToastContainer" data-sentry-source-file="ToastContainer.tsx">
      <StyledToaster theme={theme} toastOptions={{
      className: 'sonner-toast-root'
    }} data-sentry-element="StyledToaster" data-sentry-source-file="ToastContainer.tsx" />
    </div>;
};
export default ToastContainer;