import CompetitionModel from '@Api/models/CompetitionModel';
import CompetitionOnUserModel from '@Api/models/CompetitionOnUserModel';
import TestResultModel from '@Api/models/TestResultModel';
import UserModel from '@Api/models/UserModel';

import { CompetitionResultResponse } from '@Api-generated';

import { Word } from '@Store/useTypingStore.types';

export default class CompetitionResultModel implements CompetitionResultResponse {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public isLocked: boolean;
  public testResult: TestResultModel;
  public competition: CompetitionModel;
  public user: UserModel;
  public competitionOnUser?: CompetitionOnUserModel | null;
  public words?: Word[];

  constructor(
    id: string,
    createdAt: string,
    updatedAt: string,
    isLocked: boolean,
    testResult: TestResultModel,
    competition: CompetitionModel,
    user: UserModel,
    competitionOnUser?: CompetitionOnUserModel | null,
    words?: Word[]
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isLocked = isLocked;
    this.testResult = testResult;
    this.competition = competition;
    this.user = user;
    this.competitionOnUser = competitionOnUser;
    this.words = words;
  }

  public static hydrateFromApi(competitionResultResponse: CompetitionResultResponse) {
    const competitionModel = CompetitionModel.hydrateFromApi(competitionResultResponse.competition);
    const userModel = UserModel.hydrateFromApi(competitionResultResponse.user);

    const competitionOnUserModel = competitionResultResponse.competitionOnUser
      ? CompetitionOnUserModel.hydrateFromApi(competitionResultResponse.competitionOnUser)
      : competitionResultResponse.competitionOnUser;

    const testResultModel = TestResultModel.hydrateFromApi(competitionResultResponse.testResult);

    let words = undefined;
    if (competitionResultResponse.words) {
      words = competitionResultResponse.words as Word[];
    }

    return new CompetitionResultModel(
      competitionResultResponse.id,
      competitionResultResponse.createdAt,
      competitionResultResponse.updatedAt,
      competitionResultResponse.isLocked,
      testResultModel,
      competitionModel,
      userModel,
      competitionOnUserModel,
      words
    );
  }
}
