import Color from 'color';

import { colorBlack, colorWhite } from '@Assets/styles/theme/theme';

export const adjustAlpha = (hexColor: string, alpha: number): string => {
  return Color(hexColor).alpha(alpha).hexa();
};

export const toHex = (color: string): string => {
  return Color(color).hex();
};

export const getContrastTextColor = (backgroundColor: string): string => {
  if (Color(backgroundColor).isDark()) {
    return colorWhite;
  }

  return colorBlack;
};

export const changeTowardsContrast = (hexColor: string, ratio: number): string => {
  const color = Color(hexColor);

  if (color.isDark()) {
    return color.mix(Color(colorWhite), ratio).hexa();
  }

  return color.mix(Color(colorBlack), ratio).hexa();
};

export const grayscaleWithAlpha = (hexColor: string): string => {
  const color = Color(hexColor);

  return color.grayscale().alpha(color.alpha()).hexa();
};

export const isValidColor = (color: string): boolean => {
  return /^#[0-9A-F]{8}$/i.test(color);
};

export const blendHexColors = (bottomHex: string, topHex: string) => {
  const alphaTop = Color(topHex).alpha();
  const bottomColor = Color(bottomHex).rgb().object();
  const topColor = Color(topHex).rgb().object();

  const rFinal = Math.round(topColor.r * alphaTop + bottomColor.r * (1 - alphaTop));
  const gFinal = Math.round(topColor.g * alphaTop + bottomColor.g * (1 - alphaTop));
  const bFinal = Math.round(topColor.b * alphaTop + bottomColor.b * (1 - alphaTop));

  return Color({ r: rFinal, g: gFinal, b: bFinal }).hex().toUpperCase();
};

export const getGradientColorAtPosition = (
  backgroundStart: string,
  backgroundEnd: string,
  positionPercent: number
): Color => {
  const startColor = Color(backgroundStart).rgb().object();
  const endColor = Color(backgroundEnd).rgb().object();

  const interpolate = (start: number, end: number, percent: number) =>
    Math.round(start + ((end - start) * percent) / 100);

  // calculate background color at given position
  const rGradient = interpolate(startColor.r, endColor.r, positionPercent);
  const gGradient = interpolate(startColor.g, endColor.g, positionPercent);
  const bGradient = interpolate(startColor.b, endColor.b, positionPercent);

  // background color at given position
  return Color({ r: rGradient, g: gGradient, b: bGradient });
};
