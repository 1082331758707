import { Toaster } from 'sonner';
import styled from 'styled-components';

export const StyledToaster = styled(Toaster)`
  .sonner-toast-root {
    background-color: ${(props) => props.theme.palette.popOver.background.default};
    font-family: ${(props) => props.theme.font.primaryFamily};
    font-size: ${(props) => props.theme.font.text.light.medium.fontSize};
    font-weight: ${(props) => props.theme.font.text.light.medium.fontWeight};
    line-height: ${(props) => props.theme.font.text.light.medium.lineHeight};
    letter-spacing: ${(props) => props.theme.font.text.light.medium.letterSpacing};
    color: ${(props) => props.theme.palette.text.primary};
    padding: ${(props) => props.theme.space.default_16};

    transition:
      color ${(props) => props.theme.transition.theme},
      background-color ${(props) => props.theme.transition.theme};
  }

  .sonner-achievement {
    width: 432px;

    > div {
      max-width: 400px; // 432px - 16px padding left and right
    }
  }
`;
