import { TestResultResponse } from '@Api-generated';

export default class TestResultModel {
  public correctWords: number;
  public correctChars: number;
  public correctKeystrokes: number;
  public wrongWords: number;
  public wrongChars: number;
  public wrongKeystrokes: number;
  public modificationChars: number;
  public modificationKeystrokes: number;
  public spaces: number;
  public extraChars: number;
  public extraKeystrokes: number;
  public missedChars: number;
  public missedKeystrokes: number;
  public totalKeys: number;
  public extraKeys: number;
  public skippedWords: number;
  public durationInSeconds: number;
  public accuracy: number;
  public score: number;
  public wpm: number;
  public wpmRaw: number;
  public pace: number;
  public cpm: number;
  public cpmRaw: number;
  public kpm: number;
  public kpmRaw: number;
  public consistency: number;

  constructor(
    correctWords: number,
    correctChars: number,
    correctKeystrokes: number,
    wrongWords: number,
    wrongChars: number,
    wrongKeystrokes: number,
    modificationChars: number,
    modificationKeystrokes: number,
    spaces: number,
    extraChars: number,
    extraKeystrokes: number,
    missedChars: number,
    missedKeystrokes: number,
    totalKeys: number,
    extraKeys: number,
    skippedWords: number,
    durationInSeconds: number,
    accuracy: number,
    score: number,
    wpm: number,
    wpmRaw: number,
    pace: number,
    cpm: number,
    cpmRaw: number,
    kpm: number,
    kpmRaw: number,
    consistency: number
  ) {
    this.correctWords = correctWords;
    this.correctChars = correctChars;
    this.correctKeystrokes = correctKeystrokes;
    this.wrongWords = wrongWords;
    this.wrongChars = wrongChars;
    this.wrongKeystrokes = wrongKeystrokes;
    this.modificationChars = modificationChars;
    this.modificationKeystrokes = modificationKeystrokes;
    this.spaces = spaces;
    this.extraChars = extraChars;
    this.extraKeystrokes = extraKeystrokes;
    this.missedChars = missedChars;
    this.missedKeystrokes = missedKeystrokes;
    this.totalKeys = totalKeys;
    this.extraKeys = extraKeys;
    this.skippedWords = skippedWords;
    this.durationInSeconds = durationInSeconds;
    this.accuracy = accuracy;
    this.score = score;
    this.wpm = wpm;
    this.wpmRaw = wpmRaw;
    this.pace = pace;
    this.cpm = cpm;
    this.cpmRaw = cpmRaw;
    this.kpm = kpm;
    this.kpmRaw = kpmRaw;
    this.consistency = consistency;
  }

  public static hydrateFromApi(testResult: TestResultResponse) {
    return new TestResultModel(
      testResult.correctWords,
      testResult.correctChars,
      testResult.correctKeystrokes,
      testResult.wrongWords,
      testResult.wrongChars,
      testResult.wrongKeystrokes,
      testResult.modificationChars,
      testResult.modificationKeystrokes,
      testResult.spaces,
      testResult.extraChars,
      testResult.extraKeystrokes,
      testResult.missedChars,
      testResult.missedKeystrokes,
      testResult.totalKeys,
      testResult.extraKeys,
      testResult.skippedWords,
      testResult.durationInSeconds,
      testResult.accuracy,
      testResult.score,
      testResult.wpm,
      testResult.wpmRaw,
      testResult.pace,
      testResult.cpm,
      testResult.cpmRaw,
      testResult.kpm,
      testResult.kpmRaw,
      testResult.consistency
    );
  }
}
