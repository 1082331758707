import styled from 'styled-components';

import GradientBorder from '@Components/atoms/GradientBorder/GradientBorder';
import Headline from '@Components/atoms/Headline';
import Text from '@Components/atoms/Text';

export const NavigationBigLink = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${(props) => props.theme.space.default_16};
  padding: ${(props) => props.theme.space.large_32} ${(props) => props.theme.space.default_16};
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.box.background.primary};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.space.default_16};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledText = styled(Text)`
  margin-left: 10px;
  text-wrap: pretty;
`;

export const StyledGradientBorder = styled(GradientBorder)`
  height: 100%;
`;
