import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from '@Components/atoms/Col';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { TextSize } from '@Helpers/types/text';
import competitionIcon from '@Assets/img/icon/competition.png';
import customTypingTestIcon from '@Assets/img/icon/custom-typing-test.png';
import multiplayerIcon from '@Assets/img/icon/multiplayer.png';
import textPracticeIcon from '@Assets/img/icon/text-practice.png';
import typingTestIcon from '@Assets/img/icon/typing-test.png';
import { NavigationBigLink, StyledGradientBorder, StyledHeadline, StyledText } from './NavigationBig.styles';
const NavigationBig = (props: PropsWithClassName): React.ReactElement => {
  const router = useRouter();
  const {
    t
  } = useTranslation('index');
  const links = [{
    headline: t('mode.typing_test', {
      ns: 'global'
    }),
    text: t('navigation_big.typing_test.description'),
    icon: typingTestIcon.src,
    href: Routes.TypingTest,
    testId: 'navigation-big-typing-test'
  }, {
    headline: t('mode.competition', {
      ns: 'global'
    }),
    text: t('navigation_big.competition.description'),
    icon: competitionIcon.src,
    href: Routes.Competitions,
    testId: 'navigation-big-typing-competition'
  }, {
    headline: t('mode.multiplayer', {
      ns: 'global'
    }),
    text: t('navigation_big.multiplayer.description'),
    icon: multiplayerIcon.src,
    href: Routes.Multiplayer,
    testId: 'navigation-big-multiplayer'
  }, {
    headline: t('mode.text_practice', {
      ns: 'global'
    }),
    text: t('navigation_big.text_practice.description'),
    icon: textPracticeIcon.src,
    href: Routes.TextPractice,
    testId: 'navigation-big-text-practice'
  }, {
    headline: t('mode.custom_typing_test', {
      ns: 'global'
    }),
    text: t('navigation_big.custom_typing_test.description'),
    icon: customTypingTestIcon.src,
    href: Routes.CustomTypingTest,
    testId: 'navigation-big-customTypingTest'
  }];
  return <Row className={props.className} dataTestId="NavigationBig-root" columnGap={RowGap.Large} data-sentry-element="Row" data-sentry-component="NavigationBig" data-sentry-source-file="NavigationBig.tsx">
      {links.map((link, index) => <Col key={link.headline + index} xs={12} sm={6} md={4} lg={4}>
          <StyledGradientBorder>
            <NavigationBigLink onClick={() => router.push(link.href)} data-testid={link.testId}>
              <div>
                <StyledHeadline importance={3}>
                  <Image width={44} height={44} src={link.icon} alt="" /> {link.headline}
                </StyledHeadline>
                <StyledText size={TextSize.Medium}>{link.text}</StyledText>
              </div>
            </NavigationBigLink>
          </StyledGradientBorder>
        </Col>)}
    </Row>;
};
export default NavigationBig;