import CompetitorModel from '@Api/models/AchievementMetaData/CompetitorModel';
import FlawlessModel from '@Api/models/AchievementMetaData/FlawlessModel';
import GrinderModel from '@Api/models/AchievementMetaData/GrinderModel';
import PracticerModel from '@Api/models/AchievementMetaData/PracticerModel';
import RepeaterModel from '@Api/models/AchievementMetaData/RepeaterModel';
import TypingSpeedAdvancedModel from '@Api/models/AchievementMetaData/TypingSpeedAdvancedModel';
import TypingSpeedNormalModel from '@Api/models/AchievementMetaData/TypingSpeedNormalModel';
import WinnerModel from '@Api/models/AchievementMetaData/WinnerModel';
import UserModel from '@Api/models/UserModel';

import {
  CompetitorResponse,
  FlawlessResponse,
  GrinderResponse,
  PracticerResponse,
  RepeaterResponse,
  TypingSpeedAdvancedResponse,
  TypingSpeedNormalResponse,
  UserAchievementResponse,
  WinnerResponse,
} from '@Api-generated';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum AchievementType {
  Progress_TypingTest_TypingSpeedNormal = 'progress_typingTest_typingSpeedNormal',
  Progress_TypingTest_TypingSpeedAdvanced = 'progress_typingTest_typingSpeedAdvanced',
  Progress_TypingTest_Grinder = 'progress_typingTest_grinder',
  Progress_TypingTest_Flawless = 'progress_typingTest_flawless',
  Progress_Competition_Competitor = 'progress_competition_competitor',
  Progress_Competition_Winner = 'progress_competition_winner',
  Progress_Competition_Repeater = 'progress_competition_repeater',
  Progress_TextPractice_Practicer = 'progress_textPractice_practicer',
  Progress_Multiplayer_Racer = 'progress_multiplayer_racer',
  Progress_Multiplayer_Brawler = 'progress_multiplayer_brawler',

  Reward_Theme_WhoTurnedTheLightsOff = 'reward_theme_whoTurnedTheLightsOff',
  Reward_CustomTypingTest_Fanboy = 'reward_customTypingTest_fanboy',
  Reward_GameMode_VarietyVoyager = 'reward_gameMode_varietyVoyager',
  Reward_GameMode_OneArmBehindMyBack = 'reward_gameMode_oneArmBehindMyBack',
  Reward_GameMode_Afk = 'reward_gameMode_afk',
  Reward_General_Asdf = 'reward_general_asdf',
  Reward_Result_Scientist = 'reward_result_scientist',
  Reward_General_StayAWhileAndListen = 'reward_general_stayAWhileAndListen',

  Reward_TextPractice_QuickBrownFox = 'reward_textPractice_quickBrownFox',
  Reward_Social_OneOfUs = 'reward_social_oneOfUs',
  Reward_Theme_LetThereBeLight = 'reward_theme_letThereBeLight',
  Reward_GameMode_Skippy = 'reward_gameMode_skippy',
  Reward_GameMode_MorningShift = 'reward_gameMode_morningShift',
  Reward_GameMode_NightShift = 'reward_gameMode_nightShift',
  Reward_GameMode_Pacemaker = 'reward_gameMode_pacemaker',
  Reward_GameMode_HappyNewYear = 'reward_gameMode_happyNewYear',
  Reward_Social_Superfan = 'reward_social_superfan',
  Reward_Social_ProfileDescription = 'reward_social_profileDescription',
  Reward_GameMode_ClimbingTheLadder = 'reward_gameMode_climbingTheLadder',
  Reward_TextPractice_TheNumbers = 'reward_textPractice_theNumbers',
  Reward_TextPractice_FirstRating = 'reward_textPractice_firstRating',

  LegacyReward_TypingTest_TestsTaken10 = 'legacy_reward_typingTest_testsTaken10',
  LegacyReward_TypingTest_TestsTaken25 = 'legacy_reward_typingTest_testsTaken25',
  LegacyReward_TypingTest_TestsTaken50 = 'legacy_reward_typingTest_testsTaken50',
  LegacyReward_TypingTest_TestsTaken100 = 'legacy_reward_typingTest_testsTaken100',
  LegacyReward_TypingTest_TestsTaken250 = 'legacy_reward_typingTest_testsTaken250',
  LegacyReward_TypingTest_TestsTaken500 = 'legacy_reward_typingTest_testsTaken500',
  LegacyReward_TypingTest_TestsTaken750 = 'legacy_reward_typingTest_testsTaken750',
  LegacyReward_TypingTest_TestsTaken1000 = 'legacy_reward_typingTest_testsTaken1000',
  LegacyReward_TypingTest_TestsTaken2500 = 'legacy_reward_typingTest_testsTaken2500',
  LegacyReward_TypingTest_TestsTaken5000 = 'legacy_reward_typingTest_testsTaken5000',
  LegacyReward_TypingTest_TestsTaken7500 = 'legacy_reward_typingTest_testsTaken7500',
  LegacyReward_TypingTest_TestsTaken10000 = 'legacy_reward_typingTest_testsTaken10000',
  LegacyReward_TypingTest_Flawless3 = 'legacy_reward_typingTest_flawless3',
  LegacyReward_TypingTest_Flawless5 = 'legacy_reward_typingTest_flawless5',
  LegacyReward_TypingTest_Flawless10 = 'legacy_reward_typingTest_flawless10',
  LegacyReward_TypingTest_WPM50 = 'legacy_reward_typingTest_wpm50',
  LegacyReward_TypingTest_WPM60 = 'legacy_reward_typingTest_wpm60',
  LegacyReward_TypingTest_WPM70 = 'legacy_reward_typingTest_wpm70',
  LegacyReward_TypingTest_WPM80 = 'legacy_reward_typingTest_wpm80',
  LegacyReward_TypingTest_WPM90 = 'legacy_reward_typingTest_wpm90',
  LegacyReward_TypingTest_WPM100 = 'legacy_reward_typingTest_wpm100',
  LegacyReward_Social_Translator = 'legacy_reward_social_translator',
  LegacyReward_Competition_CompetitionsTaken10 = 'legacy_reward_competition_competitionsTaken10',
  LegacyReward_Competition_CompetitionsTaken25 = 'legacy_reward_competition_competitionsTaken25',
  LegacyReward_Competition_CompetitionsTaken50 = 'legacy_reward_competition_competitionsTaken50',
  LegacyReward_Competition_CompetitionsTaken100 = 'legacy_reward_competition_competitionsTaken100',
  LegacyReward_Competition_CompetitionsTaken250 = 'legacy_reward_competition_competitionsTaken250',
  LegacyReward_Competition_CompetitionsTaken500 = 'legacy_reward_competition_competitionsTaken500',
  LegacyReward_Competition_CompetitionsTaken1000 = 'legacy_reward_competition_competitionsTaken1000',
  LegacyReward_Competition_CompetitionsTaken2500 = 'legacy_reward_competition_competitionsTaken2500',
  LegacyReward_Competition_CompetitionsTaken5000 = 'legacy_reward_competition_competitionsTaken5000',
  LegacyReward_Competition_CompetitionsTaken7500 = 'legacy_reward_competition_competitionsTaken7500',
  LegacyReward_Competition_CompetitionsTaken10000 = 'legacy_reward_competition_competitionsTaken10000',
  LegacyReward_Competition_CompetitionsWon1 = 'legacy_reward_competition_competitionsWon1',
  LegacyReward_Competition_CompetitionsWon3 = 'legacy_reward_competition_competitionsWon3',
  LegacyReward_Competition_CompetitionsWon5 = 'legacy_reward_competition_competitionsWon5',
  LegacyReward_Competition_CompetitionsWon10 = 'legacy_reward_competition_competitionsWon10',
  LegacyReward_Competition_CompetitionsWon25 = 'legacy_reward_competition_competitionsWon25',
  LegacyReward_Competition_CompetitionsWon50 = 'legacy_reward_competition_competitionsWon50',
  LegacyReward_Competition_FriendlyFire3 = 'legacy_reward_competition_friendlyFire3',
  LegacyReward_Competition_FriendlyFire5 = 'legacy_reward_competition_friendlyFire5',
  LegacyReward_Competition_FriendlyFire10 = 'legacy_reward_competition_friendlyFire10',
  LegacyReward_Competition_Grinder3 = 'legacy_reward_competition_grinder3',
  LegacyReward_Competition_Grinder5 = 'legacy_reward_competition_grinder5',
  LegacyReward_Competition_Grinder10 = 'legacy_reward_competition_grinder10',
  LegacyReward_Competition_Grinder25 = 'legacy_reward_competition_grinder25',
  LegacyReward_Competition_Grinder50 = 'legacy_reward_competition_grinder50',
  LegacyReward_Social_Supporter = 'legacy_reward_social_supporter',
}

export enum AchievementCategory {
  Progress = 'progress',
  Reward = 'reward',
  LegacyReward = 'legacyReward',
}

export type AchievementMetaData =
  | TypingSpeedNormalModel
  | TypingSpeedAdvancedModel
  | GrinderModel
  | FlawlessModel
  | CompetitorModel
  | RepeaterModel
  | WinnerModel
  | PracticerModel;

export default class UserAchievementModel implements UserAchievementResponse {
  public id: string;
  public title: string;
  public description: string;
  public createdAt: string;
  public updatedAt: string;
  public metaData: AchievementMetaData | null;
  public achievementType: AchievementType;
  public category: AchievementCategory;
  public level: number;
  public levelMax: number | null;
  public userPercentage: number;
  public progress: number | null;
  public isCompleted: boolean;
  public isVisible: boolean;
  public user: UserModel;

  constructor(
    id: string,
    title: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    metaData: AchievementMetaData | null,
    achievementType: AchievementType,
    category: AchievementCategory,
    level: number,
    levelMax: number | null,
    userPercentage: number,
    progress: number | null,
    isCompleted: boolean,
    isVisible: boolean,
    user: UserModel
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.metaData = metaData;
    this.achievementType = achievementType;
    this.category = category;
    this.level = level;
    this.levelMax = levelMax;
    this.userPercentage = userPercentage;
    this.progress = progress;
    this.isCompleted = isCompleted;
    this.isVisible = isVisible;
    this.user = user;
  }

  public static hydrateFromApi(userAchievementResponse: UserAchievementResponse) {
    const userModel = UserModel.hydrateFromApi(userAchievementResponse.user);

    const achievementType = userAchievementResponse.achievementType as AchievementType;
    const category = userAchievementResponse.category as AchievementCategory;

    const metaDataModel = this.hydrateSerializable(userAchievementResponse, achievementType);

    return new UserAchievementModel(
      userAchievementResponse.id,
      userAchievementResponse.title,
      userAchievementResponse.description,
      userAchievementResponse.createdAt,
      userAchievementResponse.updatedAt,
      metaDataModel,
      achievementType,
      category,
      userAchievementResponse.level,
      userAchievementResponse.levelMax,
      userAchievementResponse.userPercentage,
      userAchievementResponse.progress,
      userAchievementResponse.isCompleted,
      userAchievementResponse.isVisible,
      userModel
    );
  }

  public static hydrateSerializable(
    userAchievementResponse: UserAchievementResponse,
    achievementType: AchievementType
  ): AchievementMetaData | null {
    switch (achievementType) {
      case AchievementType.Progress_TypingTest_TypingSpeedNormal:
        return TypingSpeedNormalModel.hydrateFromApi(userAchievementResponse.metaData as TypingSpeedNormalResponse);
      case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
        return TypingSpeedAdvancedModel.hydrateFromApi(userAchievementResponse.metaData as TypingSpeedAdvancedResponse);
      case AchievementType.Progress_TypingTest_Grinder:
        return GrinderModel.hydrateFromApi(userAchievementResponse.metaData as GrinderResponse);
      case AchievementType.Progress_TypingTest_Flawless:
        return FlawlessModel.hydrateFromApi(userAchievementResponse.metaData as FlawlessResponse);
      case AchievementType.Progress_Competition_Competitor:
        return CompetitorModel.hydrateFromApi(userAchievementResponse.metaData as CompetitorResponse);
      case AchievementType.Progress_Competition_Winner:
        return WinnerModel.hydrateFromApi(userAchievementResponse.metaData as WinnerResponse);
      case AchievementType.Progress_Competition_Repeater:
        return RepeaterModel.hydrateFromApi(userAchievementResponse.metaData as RepeaterResponse);
      case AchievementType.Progress_TextPractice_Practicer:
        return PracticerModel.hydrateFromApi(userAchievementResponse.metaData as PracticerResponse);
      case AchievementType.Reward_Theme_WhoTurnedTheLightsOff:
      case AchievementType.Reward_CustomTypingTest_Fanboy:
      case AchievementType.Reward_GameMode_OneArmBehindMyBack:
      case AchievementType.Reward_GameMode_VarietyVoyager:
      case AchievementType.Reward_General_Asdf:
      case AchievementType.Reward_Result_Scientist:
      case AchievementType.Reward_General_StayAWhileAndListen:
      case AchievementType.Reward_GameMode_Afk:
      case AchievementType.Reward_TextPractice_QuickBrownFox:
      case AchievementType.Reward_Social_OneOfUs:
      case AchievementType.Reward_Theme_LetThereBeLight:
      case AchievementType.Reward_GameMode_Skippy:
      case AchievementType.Reward_GameMode_MorningShift:
      case AchievementType.Reward_GameMode_NightShift:
      case AchievementType.Reward_GameMode_Pacemaker:
      case AchievementType.Reward_GameMode_HappyNewYear:
      case AchievementType.Reward_Social_Superfan:
      case AchievementType.Reward_Social_ProfileDescription:
      case AchievementType.Reward_GameMode_ClimbingTheLadder:
      case AchievementType.Reward_TextPractice_TheNumbers:
      case AchievementType.Reward_TextPractice_FirstRating:
      case AchievementType.LegacyReward_TypingTest_TestsTaken10:
      case AchievementType.LegacyReward_TypingTest_TestsTaken25:
      case AchievementType.LegacyReward_TypingTest_TestsTaken50:
      case AchievementType.LegacyReward_TypingTest_TestsTaken100:
      case AchievementType.LegacyReward_TypingTest_TestsTaken250:
      case AchievementType.LegacyReward_TypingTest_TestsTaken500:
      case AchievementType.LegacyReward_TypingTest_TestsTaken750:
      case AchievementType.LegacyReward_TypingTest_TestsTaken1000:
      case AchievementType.LegacyReward_TypingTest_TestsTaken2500:
      case AchievementType.LegacyReward_TypingTest_TestsTaken5000:
      case AchievementType.LegacyReward_TypingTest_TestsTaken7500:
      case AchievementType.LegacyReward_TypingTest_TestsTaken10000:
      case AchievementType.LegacyReward_TypingTest_Flawless3:
      case AchievementType.LegacyReward_TypingTest_Flawless5:
      case AchievementType.LegacyReward_TypingTest_Flawless10:
      case AchievementType.LegacyReward_TypingTest_WPM50:
      case AchievementType.LegacyReward_TypingTest_WPM60:
      case AchievementType.LegacyReward_TypingTest_WPM70:
      case AchievementType.LegacyReward_TypingTest_WPM80:
      case AchievementType.LegacyReward_TypingTest_WPM90:
      case AchievementType.LegacyReward_TypingTest_WPM100:
      case AchievementType.LegacyReward_Social_Translator:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken10:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken25:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken50:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken100:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken250:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken500:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken1000:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken2500:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken5000:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken7500:
      case AchievementType.LegacyReward_Competition_CompetitionsTaken10000:
      case AchievementType.LegacyReward_Competition_CompetitionsWon1:
      case AchievementType.LegacyReward_Competition_CompetitionsWon3:
      case AchievementType.LegacyReward_Competition_CompetitionsWon5:
      case AchievementType.LegacyReward_Competition_CompetitionsWon10:
      case AchievementType.LegacyReward_Competition_CompetitionsWon25:
      case AchievementType.LegacyReward_Competition_CompetitionsWon50:
      case AchievementType.LegacyReward_Competition_FriendlyFire3:
      case AchievementType.LegacyReward_Competition_FriendlyFire5:
      case AchievementType.LegacyReward_Competition_FriendlyFire10:
      case AchievementType.LegacyReward_Competition_Grinder3:
      case AchievementType.LegacyReward_Competition_Grinder5:
      case AchievementType.LegacyReward_Competition_Grinder10:
      case AchievementType.LegacyReward_Competition_Grinder25:
      case AchievementType.LegacyReward_Competition_Grinder50:
      case AchievementType.LegacyReward_Social_Supporter:
        return null;
      case AchievementType.Progress_Multiplayer_Racer:
      case AchievementType.Progress_Multiplayer_Brawler:
        throw new Error('Unexpected Error: Achievement Type is not supported yet');
    }
  }
}
