import { IconLanguage, IconPalette, IconUsers } from '@tabler/icons-react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import { AntiCheatGameResult } from '@Api/models/NotificationMetaData/AntiCheatToUnlockResultModel';
import Container from '@Components/atoms/Container';
import { Icon } from '@Components/atoms/Icon/Icon';
import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import MenuButton from '@Components/atoms/MenuButton/MenuButton';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import Text from '@Components/atoms/Text';
import { Tooltip, TooltipContent, TooltipTrigger } from '@Components/atoms/Tooltip/Tooltip';
import { UserImageSize } from '@Components/atoms/UserImage/UserImage';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { useCounter } from '@Components/helper/animation';
import { useNotificationPolling } from '@Components/helper/useNotificationPolling';
import { LogoVariant } from '@Components/molecules/Logo/Logo';
import { Routes } from '@Components/Routes';
import useGlobalColyseusRoomStore from '@Store/useGlobalColyseusRoomStore';
import { TextColor, TextSize } from '@Helpers/types/text';
import { iconStroke } from '@Assets/styles/theme/theme';
import { Chevron, Content, Counter, GameModes, GameModesText, HeaderRow, LanguageIcon, LoginSignupLink, MobileLogo, Root, StyledRow, StyledUserImage, TabletDesktopLogo, UserImageWrapper, Username, UserRow, VisitorCounter, VisitorsOnline } from './Header.styles';
const Sidebar = dynamic(() => import('@Components/molecules/Sidebar/Sidebar'), {
  ssr: false
});
const AntiCheatModal = dynamic(() => import('@Components/organisms/AntiCheatModal/AntiCheatModal'), {
  ssr: false
});
export type Content = 'default' | 'languages' | 'themes' | 'notifications' | 'modes' | null;
interface Props extends PropsWithClassName {
  serverUser?: LocalStorageUserModel;
}
const Header = (props: Props): React.ReactElement => {
  const {
    serverUser
  } = props;
  const pathname = usePathname();
  const {
    t
  } = useTranslation('global');
  const {
    user: contextUser,
    isGlobalContextInitialized
  } = useGlobalContext();
  const [visitorsCounterInit, setVisitorsCounterInit] = useState(0);
  const {
    visitorCount,
    prevVisitorCount
  } = useGlobalColyseusRoomStore(state => ({
    visitorCount: state.visitorCount,
    prevVisitorCount: state.prevVisitorCount
  }));
  const visitorsCounter = useCounter(visitorsCounterInit ?? 0, 2, {
    x1: 0,
    y1: 1,
    x2: 0.2,
    y2: 0.96
  }, 0, prevVisitorCount);
  useEffect(() => {
    if (prevVisitorCount > 0) {
      setVisitorsCounterInit(visitorCount);
    }
  }, [visitorCount, prevVisitorCount]);
  const user = useMemo(() => isGlobalContextInitialized ? contextUser : serverUser, [contextUser, serverUser, isGlobalContextInitialized]);
  const [content, setContent] = useState<Content>(null);
  const [notifications, refreshNotifications] = useNotificationPolling(contextUser, 'app', content === 'notifications');
  const [isSidebarUnmounting, setSidebarIsUnmounting] = useState(false);
  const [result, setResult] = useState<AntiCheatGameResult | null>(null);
  const closeSidebar = useCallback(() => {
    if (content === null) {
      return;
    }
    setSidebarIsUnmounting(true);
    setTimeout(() => {
      setContent(null);
      setSidebarIsUnmounting(false);
    }, 500);
  }, [content]);
  useLayoutEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeSidebar();
      }
    };
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeSidebar]);
  const handleIconClick = (clickedContent: Content) => {
    if (isSidebarUnmounting) {
      return;
    }
    if (content === clickedContent) {
      closeSidebar();
      return;
    }
    setContent(clickedContent);
  };
  const renderUserLoginOrUserArea = () => {
    if (user) {
      let counter = 0;
      if (notifications && notifications.length > 0) {
        const unreadNotifications = notifications.filter(notification => notification.readAt === null);
        counter = unreadNotifications.length;
      }
      return <div onClick={() => handleIconClick('default')}>
          <UserRow noGutters rowGap={RowGap.Small} xsMiddle dataTestId="user-trigger-root">
            <UserImageWrapper>
              {counter > 0 && <Counter>{counter}</Counter>}
              <StyledUserImage $isOpen={content === 'default' && !isSidebarUnmounting} width={34} height={34} userImageSize={UserImageSize.Small} userImageSrcModel={user.gravatar} />
            </UserImageWrapper>
            <Username size={TextSize.Medium} color={content === 'default' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} dataTestId="user-trigger-username">
              {user.username}
            </Username>
          </UserRow>
        </div>;
    }
    return <>
        <StyledRow noGutters rowGap={RowGap.Default} xsMiddle data-sentry-element="StyledRow" data-sentry-source-file="Header.tsx">
          <LoginSignupLink href={Routes.Login} size={LinkSize.Medium} weight={LinkWeight.Light} onClick={closeSidebar} hideUnderline dataTestId="header-button-login" data-sentry-element="LoginSignupLink" data-sentry-source-file="Header.tsx">
            {t('navigation.header.login')}
          </LoginSignupLink>
          <LoginSignupLink href={Routes.SignUp} size={LinkSize.Medium} weight={LinkWeight.Light} onClick={closeSidebar} hideUnderline dataTestId="header-button-signup" data-sentry-element="LoginSignupLink" data-sentry-source-file="Header.tsx">
            {t('navigation.header.signup')}
          </LoginSignupLink>
        </StyledRow>
        <MenuButton isOpen={content === 'default' && !isSidebarUnmounting} onClick={() => handleIconClick('default')} data-sentry-element="MenuButton" data-sentry-source-file="Header.tsx" />
      </>;
  };
  const getGameModeName = () => {
    if (pathname.startsWith('/typing-test')) {
      return t('mode.typing_test');
    }
    if (pathname.startsWith('/competition')) {
      return t('mode.competition');
    }
    if (pathname.startsWith('/text-practice')) {
      return t('mode.text_practice');
    }
    if (pathname.startsWith('/multiplayer')) {
      return t('mode.multiplayer');
    }
    if (pathname.startsWith('/custom-typing-test')) {
      return t('mode.custom_typing_test');
    }
    return t('navigation.header.typing_games');
  };
  return <>
      <Root className={props.className} data-testid={'Header-root'} data-sentry-element="Root" data-sentry-source-file="Header.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="Header.tsx">
          <HeaderRow xsBetween noGutters xsMiddle data-sentry-element="HeaderRow" data-sentry-source-file="Header.tsx">
            <TabletDesktopLogo height={32} onClick={closeSidebar} dataTestId="header-logo" isHeader data-sentry-element="TabletDesktopLogo" data-sentry-source-file="Header.tsx" />
            <MobileLogo height={32} onClick={closeSidebar} variant={LogoVariant.Logo} dataTestId="header-logo-mobile" isHeader data-sentry-element="MobileLogo" data-sentry-source-file="Header.tsx" />
            <Content rowGap={RowGap.Large} noGutters xsMiddle data-sentry-element="Content" data-sentry-source-file="Header.tsx">
              {visitorCount > 0 && <VisitorsOnline>
                  <Tooltip>
                    <TooltipContent isSmall>
                      <Text size={TextSize.Medium}>{t('navigation.visitor_count.tooltip')}</Text>
                    </TooltipContent>
                    <TooltipTrigger open={false}>
                      <Row noGutters xsMiddle rowGap={RowGap.Small}>
                        <Icon icon={IconUsers} strokeWidth={iconStroke} color={TextColor.Header} />
                        <VisitorCounter size={TextSize.Medium} color={TextColor.Header}>
                          {visitorsCounter === '0' ? visitorCount : visitorsCounter}
                        </VisitorCounter>
                      </Row>
                    </TooltipTrigger>
                  </Tooltip>
                </VisitorsOnline>}
              <GameModes onClick={() => handleIconClick('modes')} data-testid="modes-trigger-root" data-sentry-element="GameModes" data-sentry-source-file="Header.tsx">
                <Chevron $isActive={content === 'modes' && !isSidebarUnmounting} onClick={() => handleIconClick('modes')} data-sentry-element="Chevron" data-sentry-source-file="Header.tsx" />
                <GameModesText size={TextSize.Medium} color={content === 'modes' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} data-sentry-element="GameModesText" data-sentry-source-file="Header.tsx">
                  {getGameModeName()}
                </GameModesText>
              </GameModes>
              <Icon icon={IconPalette} stroke={iconStroke} onClick={() => handleIconClick('themes')} color={content === 'themes' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} dataTestId="themes-trigger-root" hoverColor={TextColor.ThemePrimary} data-sentry-element="Icon" data-sentry-source-file="Header.tsx" />
              <LanguageIcon icon={IconLanguage} stroke={iconStroke} onClick={() => handleIconClick('languages')} color={content === 'languages' && !isSidebarUnmounting ? TextColor.ThemePrimary : TextColor.Header} dataTestId="languages-trigger-root" hoverColor={TextColor.ThemePrimary} data-sentry-element="LanguageIcon" data-sentry-source-file="Header.tsx" />
              {renderUserLoginOrUserArea()}
            </Content>
          </HeaderRow>
        </Container>
      </Root>
      {content && <Sidebar content={content} updateContent={setContent} isUnmounting={isSidebarUnmounting} handleCloseSidebar={closeSidebar} notifications={notifications} refreshNotifications={refreshNotifications} handleAntiCheatResultUpdate={setResult} />}
      {result && <AntiCheatModal result={result} onClose={() => setResult(null)} onSuccess={() => setResult(null)} />}
    </>;
};
export default Header;