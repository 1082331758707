import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { useGlobalColyseusRoom } from '@Components/helper/colyseus/useGlobalColyseusRoom';
interface Props extends PropsWithClassName {
  children?: React.ReactElement;
}
const GlobalColyseusRoomContainer = (props: Props): React.ReactElement | undefined => {
  useGlobalColyseusRoom();
  return props.children;
};
export default GlobalColyseusRoomContainer;